<template>
  <div>
    <div v-if="status.status_sistem === 'Buka'">
      <Buka
        :url="url"
        :token="token"
        :user="user"
        :idpm="idpm"
        :idppb="idppb"
        :idprofil="idprofil"
        :tahun="tahun"
      />
    </div>
    <div v-else-if="status.status_sistem === 'Tutup Sementara'">
      <Sementara
        :url="url"
        :token="token"
        :user="user"
        :idpm="idpm"
        :idppb="idppb"
        :idprofil="idprofil"
        :tahun="tahun"
      />
    </div>
    <div v-else>
      <Permanen
        :url="url"
        :token="token"
        :user="user"
        :idpm="idpm"
        :idppb="idppb"
        :idprofil="idprofil"
        :tahun="tahun"
      />
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import Buka from "./ppbkl/buka";
import Sementara from "./ppbkl/tutup_sementara";
import Permanen from "./ppbkl/permanen";
import { LOGOUT } from "@/core/services/store/auth.module";
export default {
  components: { Buka, Sementara, Permanen },
  data() {
    return {
      url: localStorage.getItem("baseapi"),
      token: localStorage.getItem("id_token"),
      user: JSON.parse(localStorage.getItem("user")),
      idpm: localStorage.getItem("idpm"),
      idprofil: localStorage.getItem("idprofil"),
      tahun: 2024,
      status: [],
      tanggal: localStorage.getItem("tanggal"),
      idppb: localStorage.getItem("idppb"),
    };
  },
  mounted() {
    this.getkegiatan();
  },
  methods: {
    getkegiatan() {
      axios
        .get(
          this.url +
            `/tanggal_kegiatans?filter=tanggal_mulai,<=,` +
            this.tanggal +
            `;tanggal_selesai,>=,` +
            this.tanggal,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.status = response.data.data;
          console.log(this.status.status_sistem);
          this.notifikasi();
        })
        .catch((error) => {
          Swal.fire({
            title: error.response.data,
            text: "",
            icon: "error",
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$store
                .dispatch(LOGOUT)
                .then(() => this.$router.push({ name: "login" }));
            }
          });
          console.log(error);
          return error;
        });
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY");
      }
    },
    notifikasi() {
      if (this.status.status_sistem === "Buka") {
        Swal.fire({
          title: "",
          html:
            "Penilaian Mandiri saat ini telah dibuka<br>" +
            this.format_date(this.status.tanggal_mulai) +
            " sd " +
            this.format_date(this.status.tanggal_selesai),
          icon: "info",
          confirmButtonClass: "btn btn-secondary",
        });
      } else if (this.status.status_sistem === "Tutup Sementara") {
        Swal.fire({
          title: "",
          html:
            "Penilaian Mandiri saat ini telah ditutup sementara<br>" +
            this.format_date(this.status.tanggal_mulai) +
            " sd " +
            this.format_date(this.status.tanggal_selesai),
          icon: "info",
          confirmButtonClass: "btn btn-secondary",
        });
      } else {
        Swal.fire({
          title: "",
          html:
            "Penilaian Mandiri saat ini telah ditutup<br>" +
            this.format_date(this.status.tanggal_mulai) +
            " sd " +
            this.format_date(this.status.tanggal_selesai),
          icon: "info",
          confirmButtonClass: "btn btn-secondary",
        });
      }
    },
  },
};
</script>